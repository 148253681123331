import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { selectToken } from '../../redux/user/user.selectors';

const PrivateRoute: React.FC<{
  path: string;
  children: any;
  exact?: boolean;
}> = ({ children, ...rest }) => {
  const location = useLocation();
  const token = useSelector(selectToken);

  if (token !== '') {
    return <Route {...rest}>{children}</Route>;
  }

  return (
    <Redirect
      to={{
        pathname: '/',
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;
