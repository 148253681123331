import axios, { AxiosInstance } from 'axios';

import { store } from './../redux/store';
import { API_URL } from './constants';

store.subscribe(listener);

function select(state: { user: any; establishment: any }) {
  return state.user.token;
}

function listener() {
  // @ts-ignore
  const token = select(store.getState());
  api.token = token;
}

interface ApiProps extends AxiosInstance {
  token?: string;
}

const api: ApiProps = axios.create({
  baseURL: `${API_URL}/api`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(function (config) {
  config.headers.Authorization =
    api.token? `JWT ${api.token}` : null;

  return config;
});

export default api;
