import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { font, headerLinkColor } from './../../config/theme/index';

export const Container = styled(NavLink)`
  font-family: ${font};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: ${headerLinkColor};
  transition: 0.4s;

  width: fit-content;
  height: 100%;
  position: relative;
  transition: 0.4s;
  &::after {
    position: absolute;
    bottom: -5px;
    margin: 0 auto;
    left: 0;
    right: 0;

    content: '';
    height: 1px;
    width: 0%;

    background-color: var(--white);

    transition: 0.4s;
  }
  &.active {
    font-size: 20px;
    &::after {
      width: 100%;
    }
  }
  &.logo {
    &::after {
      display: none;
    }
  }
`;
