import React from 'react';

import spinnerWhite from '../../assets/images/spinner-white.svg';
import { Container, Spinner } from './styles';

const Loader: React.FC = () => {
  return (
    <Container>
      <Spinner src={spinnerWhite} alt='Carregando...' />;
    </Container>
  );
};

export default Loader;
