import { createGlobalStyle } from 'styled-components';

import { font, mainColor } from './theme/index';

const GlobalStyles = createGlobalStyle`
  :root {
    --baby-blue: #a8d7ff;
    --dark-sky-blue: #529ddc;
    --very-light-pink: #d5d5d5;
    --black: #000000;
    --black-two: #131313;
    --lightLavendar: #f4c3ee;
    --softPurple: #b764aa;
    --paleMauve: #ffd4fa;
    --brownishGrey: #707070;
    --kellyGreen: #00cb22;
    --dodgerBlue: #3db1ff;
    --vibrantGreen: #00e106;
    --grapeFruit: #ff5d5d;
    --sky: #7dd2ff;
    --robinsEgg: #6ae0ff;
    --blackThree: #303030;
    --midBlue: #275ba2;
    --lightBurgundy: #c23435;
    --black-three: #191919;
    --veryLightPinkTwo: #c5c5c5;
    --peacockBlue: #006e8d;
    --oceanBlue: #026c99;
    --lightMossGreen: #9acf6b;
    --deepSeaBlue: #015675;
    --peacock-blue: #006f8d;
    --grapefruit: #ff6060;
    --sunYellow: #fbcf22;
    --black-four: #2d2d2d;
    --dark: #212530;
    --very-light-pink-two: #eeeeee;
    --orange-red: #ff1f1f;
    --vibrant-green: #0bce2b;
    --light-navy: #165193;
    --faded-blue: #7286b7;
    --darkish-blue: #004383;
    --charcoalGrey: #494a4b;
    --white: #ffffff;
    --veryLightPinkThree: #bebebe;
    --whiteTwo: #f5f5f5;
    --toxicGreen: #3dd232;
    --veryLightPinkFour: #cbcbcb;
    --brownGrey: #9a9a9a;
    --brownishGreyTwo: #636363;
    --brownGreyTwo: #9b9b9b;
    --darkSlateBlue: #1c3867;
    --midnight: #030416;
    --greyishBrown: #404040;
    --lightblue: #76c0f1;
    --softBlue: #5bade4;
    --paleGrey: #f6f8fb;
    --tomato: #df3939;
    --brightLightGreen: #39df4a;
    --warmGrey: #979696;
    --darkSkyBlueTwo: #33adff;
    --sky-two: #85ceff;
    --tangerine: #fc8f00;
    --marigold: #fccc00;
    --toxic-green: #7fe229;
    --salmon: #f17676;
  }

  * {
    font-family: ${font};
  }

  body {
    background-color: ${mainColor}
  }

  .p-progress-spinner {
    @keyframes ui-progress-spinner-color {
      100%,
      0% {
          stroke: var(--midnight);
      }
      40% {
          stroke: var(--darkSlateBlue);
      }
      66% {
          stroke: var(--warmGrey);
      }
      80%,
      90% {
          stroke: var(--white);
      }
    }
  }
`;

export default GlobalStyles;
