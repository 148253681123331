import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Ativo_branca from '../../assets/images/Ativo_branca.png';
import { ReactComponent as CloseMenuSvg } from '../../assets/images/close.svg';
import { ReactComponent as OpenMenuSvg } from '../../assets/images/open-menu-white.svg';
import { logout } from '../../redux/user/user.actions';
import { selectToken } from '../../redux/user/user.selectors';
import CustomLink from '../CustomLink';
import {
  Container,
  ExitButton,
  List,
  Logo,
  OpenCloseResponsiveMenu,
  Overlay,
  PersonWelcome,
  ResponsiveMenu,
} from './styles';

export interface Props {
  width?: string;
}

const Header: React.FC = () => {
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const history = useHistory();

  const [openResponsiveMenu, setOpenResponsiveMenu] = useState<boolean>(false);

  function controlResponsiveMenu() {
    setOpenResponsiveMenu(!openResponsiveMenu);
  }

  function onLogout() {
    localStorage.clear();
    dispatch(logout());
    history.push('/login');
  }

  return (
    <>
      <Container>
        <List width={window.innerWidth >= 308 ? '100%' : 'auto'}>
          <li className='not-hide'>
            <CustomLink to='/turmas-disponiveis' className='logo'>
              <Logo src={Ativo_branca} alt='Robomind Logo' />
            </CustomLink>
          </li>

          <li>
            <CustomLink exact to='/turmas-disponiveis'>
              Turmas Disponíveis
            </CustomLink>
          </li>

          <li>
            <CustomLink exact to='/turmas-matriculadas'>
              Turmas Matriculadas
            </CustomLink>
          </li>

          <li>
            <CustomLink exact to='/historico-pagamento'>
              Histórico de pagto
            </CustomLink>
          </li>

          <li>
            <CustomLink exact to='/metodo-pagamento'>
              Método de pagto
            </CustomLink>
          </li>

          <li>
            <CustomLink exact to='/editar-matricula'>
              Editar Matrículas
            </CustomLink>
          </li>

          <li>
            {token ? (
              <>
                <PersonWelcome>Olá</PersonWelcome>

                <ExitButton onClick={onLogout}>Sair</ExitButton>
              </>
            ) : (
              <ExitButton
                onClick={() => {
                  localStorage.clear();
                  history.push('/login');
                }}
              >
                Entrar
              </ExitButton>
            )}
          </li>
        </List>

        <OpenCloseResponsiveMenu onClick={controlResponsiveMenu}>
          <OpenMenuSvg />
        </OpenCloseResponsiveMenu>
      </Container>

      <Overlay active={openResponsiveMenu} onClick={controlResponsiveMenu} />

      <ResponsiveMenu active={openResponsiveMenu}>
        <div className='menu-header'>
          <OpenCloseResponsiveMenu onClick={controlResponsiveMenu}>
            <CloseMenuSvg />
          </OpenCloseResponsiveMenu>
        </div>

        <List>
          <li>
            <CustomLink
              exact
              to='/turmas-disponiveis'
              onClick={controlResponsiveMenu}
            >
              Turmas Disponíveis
            </CustomLink>
          </li>

          <li>
            <CustomLink
              exact
              to='/turmas-matriculadas'
              onClick={controlResponsiveMenu}
            >
              Turmas Matriculadas
            </CustomLink>
          </li>

          <li>
            <CustomLink
              exact
              to='/historico-pagamento'
              onClick={controlResponsiveMenu}
            >
              Histórico de pagto
            </CustomLink>
          </li>

          <li>
            <CustomLink
              exact
              to='/metodo-pagamento'
              onClick={controlResponsiveMenu}
            >
              Método de pagto
            </CustomLink>
          </li>

          <li>
            <CustomLink
              exact
              to='/editar-matricula'
              onClick={controlResponsiveMenu}
            >
              Editar Matrículas
            </CustomLink>
          </li>

          <li>
            {token ? (
              <ExitButton onClick={onLogout}>Sair</ExitButton>
            ) : (
              <ExitButton onClick={() => history.push('/login')}>
                Entrar
              </ExitButton>
            )}
          </li>
        </List>
      </ResponsiveMenu>
    </>
  );
};

export default Header;

