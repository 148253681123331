import styled, { css } from 'styled-components';

import { RESPONSIVE_VALUE } from './../../../../config/constants';
import {
  font,
  mainColor,
  secondaryColor,
  textColor,
  titleColor,
} from './../../../../config/theme/index';

export const Container = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: space-around;
  padding: 0 15px;
`;

export const Title = styled.h1<{ marginBottom?: string }>`
  font-family: ${font};
  font-size: 17px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: ${titleColor};
  margin-bottom: ${props => props.marginBottom || '15px'};
`;

export const Column = styled.div<{ width: string }>`
  width: ${props => props.width};
  max-width: 100%;
  display: flex;
  flex-flow: wrap column;
  @media (max-width: ${RESPONSIVE_VALUE}) {
    width: 100%;
  }
`;

export const Row = styled.div<{ marginBottom?: string; height?: string }>`
  width: 100%;
  max-width: 100%;
  height: ${props => props.height || 'unset'};
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || '20px'};
  position: relative;
`;

export const InsideTitle = styled.p`
  font-family: ${font};
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: ${titleColor};
  position: absolute;
  margin-top: 30px;
`;

export const ResumeBox = styled.div`
  max-width: 276px;
  width: 100%;
  height: 260px;
  display: flex;
  flex-flow: wrap;
  border-radius: 15px;
  background-color: ${secondaryColor};
  font-family: ${font};
  padding: 10px 20px;
  .resume-value {
    width: 100%;
    display: flex;
    flex-flow: column;
    margin-bottom: 10px;
    span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.42;
      letter-spacing: normal;
      text-align: left;
      color: ${textColor};
      margin-bottom: 5px;
      @media (max-width: ${RESPONSIVE_VALUE}) {
        text-align: center;
      }
    }
    p {
      font-size: 20px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.35;
      letter-spacing: normal;
      text-align: left;
      color: ${textColor};
      @media (max-width: ${RESPONSIVE_VALUE}) {
        text-align: center;
      }
    }
  }
`;

export const ComparativeValueWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 13px;
`;

export const ComparativeValueButton = styled.button<{
  active: boolean;
  buttonType: 'CDI' | 'IBOV' | 'DOL';
}>`
  width: 82px;
  height: 52px;
  border: none;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-right: 30px;
  display: flex;
  flex-flow: column;
  justify-items: center;
  align-items: center;
  padding: 5px;
  &:focus {
    outline: none;
  }
  ${props =>
    props.active &&
    css`
      background-color: rgb(133, 206, 255, 0.4);
    `};

  p {
    font-family: ${font};
    font-size: 20px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: ${textColor};
  }
  span {
    font-family: ${font};
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    ${props =>
      props.buttonType === 'CDI' &&
      css`
        color: var(--tangerine);
      `};
    ${props =>
      props.buttonType === 'IBOV' &&
      css`
        color: var(--marigold);
      `};
    ${props =>
      props.buttonType === 'DOL' &&
      css`
        color: var(--toxic-green);
      `};
  }
`;

export const ComparativeBox = styled.div`
  width: 100%;
  min-height: 410px;
  border-radius: 20px;
  background-color: ${secondaryColor};
  display: flex;
  flex-flow: wrap;
  justify-content: center;
`;

export const ChartWrapper = styled.div`
  max-width: 100%;
  width: 964px;
  padding: 15px;
  #reactgooglegraph-1
    > div
    > div:nth-child(1)
    > div
    > svg
    > g:nth-child(4)
    > g:nth-child(2)
    > g:nth-child(2)
    > rect,
  text {
    fill: ${textColor};
  }

  .no-data {
    font-family: ${font};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: ${textColor};
  }
`;

export const ChartLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const AssetPerformanceColumn = styled.div<{ type: 'ALTA' | 'BAIXA' }>`
  width: 45%;
  height: 100%;
  h3 {
    font-family: ${font};
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 10px;
    ${props =>
      props.type === 'ALTA' &&
      css`
        color: var(--sky-two);
      `};
    ${props =>
      props.type === 'BAIXA' &&
      css`
        color: var(--salmon);
      `};
  }
  .stocks {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .stock {
      width: 45%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      p {
        font-family: ${font};
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: ${textColor};
        @media (max-width: ${RESPONSIVE_VALUE}) {
          margin-right: 10px;
        }
      }
      span {
        font-family: ${font};
        font-size: 15px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: right;
        color: ${textColor};
      }
      @media (max-width: ${RESPONSIVE_VALUE}) {
        width: 100%;
        justify-content: center;
      }
    }
  }
  @media (max-width: ${RESPONSIVE_VALUE}) {
    width: 100%;
    height: auto;
  }
`;

export const AssetPerformanceBox = styled.div`
  max-width: 90%;
  width: 650px;
  min-height: 137px;
  border-radius: 10px;
  background-color: ${secondaryColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
  padding: 10px 15px;
  .darker-line {
    width: 0.5px;
    height: 92px;
    border: solid 2px ${mainColor};
    background-color: ${mainColor};
    @media (max-width: ${RESPONSIVE_VALUE}) {
      width: 92px;
      height: 0.5px;
      margin: 10px 0;
    }
  }
  .no-data {
    width: 100%;
    font-family: ${font};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: ${textColor};
  }
  @media (max-width: ${RESPONSIVE_VALUE}) {
    justify-content: center;
  }
`;

export const NoAssets = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .circle {
    width: 60%;
    height: 60%;
    background-color: ${secondaryColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: ${font};
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: ${textColor};
  }
`;
