export enum UserActionsTypes {
  LOGOUT = 'LOG_OUT',
  LOGIN = 'LOGIN',
  SET_TYPE = 'SET_TYPE',
  SET_TOKEN = 'SET_TOKEN',
  GET_TOKEN = 'GET_TOKEN',
  SET_SCHOOL_CODE = 'SET_SCHOOL_CODE',
  GET_SCHOOL_CODE = 'GET_SCHOOL_CODE',
  SET_CONFIRMED_REGISTRATION = 'SET_CONFIRMED_REGISTRATION',
  GET_CONFIRMED_REGISTRATION = 'GET_CONFIRMED_REGISTRATION',
  SET_USER = 'SET_USER',
  SET_USER_ERROR = 'SET_USER_ERROR',
  SET_USER_STARTED = 'SET_USER_STARTED',
  SET_USER_SUCCESS = 'SET_USER_SUCCESS',
}

