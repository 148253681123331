import { Dispatch } from 'redux';

import api from '../../config/api';
import { User } from './../../model/User';
import { UserActionsTypes } from './user.types';

export const setToken = (token: string) => ({
  type: UserActionsTypes.SET_TOKEN,
  payload: token,
});

export const setType = (type: 'SUBSCRIBER' | 'USUARIO') => ({
  type: UserActionsTypes.SET_TYPE,
  payload: type,
});

export const setCode = (school_code: string) => ({
  type: UserActionsTypes.SET_SCHOOL_CODE,
  payload: school_code,
});

export const setUser = (user: User) => ({
  type: UserActionsTypes.SET_USER,
  payload: user,
});

export const setUserStarted = () => ({
  type: UserActionsTypes.SET_USER_STARTED,
});

export const setUserSuccess = (token: string, type: string, code: string) => ({
  type: UserActionsTypes.SET_USER_SUCCESS,
  payload: {
    token,
    type,
    code,
  },
});

export const setUserError = (error: any) => ({
  type: UserActionsTypes.SET_USER_ERROR,
  payload: {
    error,
  },
});

export const setUserCompletedRegistration = (
  completed_registration: boolean
) => ({
  type: UserActionsTypes.SET_CONFIRMED_REGISTRATION,
  payload: {
    completed_registration,
  },
});

export const logout = () => ({ type: UserActionsTypes.LOGOUT });

export const login = (
  token: string,
  user: User,
  type: 'SUBSCRIBER' | 'USUARIO',
  school_code: string,
  completed_registration: boolean
) => {
  return {
    type: UserActionsTypes.LOGIN,
    payload: {
      token,
      user,
      type,
      school_code,
      completed_registration,
    },
  };
};

export const fetchUser = ({ email, password }: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(setUserStarted());

    try {
      const { data } = await api.post('/v1/api-token-auth/sub/', {
        email,
        password,
      });

      if (data instanceof Object) {
        dispatch(setUserSuccess(data.token, data.type, data.school_code));
      }
    } catch (error: any) {
      dispatch(setUserError(error.response.data));
    }

    return 'done';
  };
};

