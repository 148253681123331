import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push('/');
  }, []);

  return <div style={{ backgroundColor: 'var(--midnight)' }} />;
};

export default NotFound;
