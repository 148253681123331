import { ReduxAction } from './../../model/ReduxAction';
import { User } from './../../model/User';
import { UserActionsTypes } from './user.types';

export interface UserState {
  user?: User;
  hasLocationPermission?: boolean;
  error?: any;
  loadingUser?: boolean;
  token?: string;
  school_code?: string;
  type?: 'SUBSCRIBER' | 'USUARIO';
  theme?: 'dark' | 'light';
  completed_registration?: boolean;
}

const INITIAL_STATE: UserState = {
  token: '',
  school_code: '',
  user: {},
  hasLocationPermission: false,
  loadingUser: false,
  error: null,
  theme: 'dark',
  completed_registration: true,
};

export default (
  state = INITIAL_STATE,
  action: ReduxAction<UserActionsTypes, UserState>
) => {
  const payload = action.payload || INITIAL_STATE;
  switch (action.type) {
    case UserActionsTypes.SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case UserActionsTypes.SET_TYPE:
      return {
        ...state,
        type: payload,
      };
    case UserActionsTypes.SET_SCHOOL_CODE:
      return {
        ...state,
        school_code: payload,
      };
    case UserActionsTypes.SET_USER:
      return {
        ...state,
        user: payload,
      };
    case UserActionsTypes.SET_USER_STARTED:
      return {
        ...state,
        loadingUser: true,
      };
    case UserActionsTypes.SET_USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        token: payload.token,
        type: payload.type,
        error: null,
        school_code: payload.school_code,
      };
    case UserActionsTypes.SET_USER_ERROR:
      return {
        ...state,
        loadingUser: false,
        error: payload.error,
      };
    case UserActionsTypes.SET_CONFIRMED_REGISTRATION:
      return {
        ...state,
        completed_registration: payload.completed_registration,
      };
    case UserActionsTypes.LOGOUT:
      return INITIAL_STATE;
    case UserActionsTypes.LOGIN:
      return {
        ...state,
        type: payload.type,
        user: payload.user,
        token: payload.token,
        school_code: payload.school_code,
        completed_registration: payload.completed_registration,
      };
    default:
      return state;
  }
};

